@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/animations';

.root {
    position: absolute;
    display: flex;
    width: max-content;
    height: 100%;
    align-items: center;
    border: none;
    margin-left: 2.4rem;
    background-color: transparent;
    color: var(--color-blue);

    &.isHidden {
        opacity: 0;
        transform: scale(0.85);
        transition-delay: calc(var(--timing) * 0.1);
    }
}

.icon {
    margin-right: 0.8rem;
}
