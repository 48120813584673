@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 2.4rem 4.8rem;
    margin: 0;
    background-color: var(--colors-cream);
    gap: 1rem;
    list-style-type: none;

    > li:last-of-type {
        margin-bottom: 2.4rem;
    }
}

.item {
    width: 100%;
    padding: 1.4rem;
    border: none;
    border-radius: 10rem;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    text-align: center;
    text-transform: uppercase;
}

.itemIcon {
    display: none;
}

@include large {
    .root {
        position: sticky;
        top: var(--header-height);
        padding: 0;
        background-color: var(--colors-white);
        gap: 0;

        > li:last-of-type {
            margin-bottom: 4.8rem;
        }
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0;
        border-radius: 0;
        border-bottom: 0.1rem solid rgba(color('blue'), 0.15);
        text-align: left;

        &.isActive {
            color: #41b6e6;
        }
    }

    .itemIcon {
        display: block;
        width: 0.7rem;
        color: var(--colors-blue);
        visibility: hidden;

        .isActive & {
            visibility: visible;
        }
    }
}
