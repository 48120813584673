@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    border-bottom: 0.1rem solid rgba(color('cream'), 0.2);
}

.header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1.8rem 2rem;
    border: none;
    margin: 0;
    background: none;
}

.headline {
    color: var(--colors-cream);
    text-transform: uppercase;
}

.headerIcon {
    color: var(--colors-cream);
}

.list {
    overflow: hidden;
    transition: height $default $ease;
}

.listInner {
    padding: 1rem 2rem 2rem;
    margin: 0;
}

.listItem {
    padding: 0 0 1rem;
    list-style-type: none;
}

@include medium {
    .root {
        border-bottom: none;
    }

    .header {
        padding: 0;

        .headerIcon {
            display: none;
        }
    }

    .listInner {
        padding: 1.2rem 0 0;
        padding-bottom: 0;
    }

    .listItem {
        &:last-child {
            padding-bottom: 0;
        }
    }
}
