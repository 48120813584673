@import 'styles/breakpoints';

.iconWrapper {
    position: relative;
    width: 6rem;
    height: 6rem;
    margin-bottom: 2rem;
}

@include xLarge {
    .iconWrapper {
        width: 8rem;
        height: 8rem;
    }
}
