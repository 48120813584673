@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

.root {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 2.4rem;

    &::before,
    &::after {
        position: relative;
        display: block;
        width: 100%;
        border-bottom: 1px solid rgba(color('blue'), 0.25);
        content: '';
    }
}

.buttonWrapper {
    padding: 0 1.2rem;
}

@include medium {
    .root {
        margin-top: 4.8rem;
        margin-bottom: 5.1rem;
    }

    .buttonWrapper {
        padding: 0 4em;
    }
}
