@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    position: relative;
    width: 100%;
    padding: 4.8rem 2.4rem 0;
}

.inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lockup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
}

.inner .headline {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: var(--colors-blue);
    font-size: 5.2rem;
    line-height: 85%;
    opacity: 0;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(4rem);
    transition: opacity $default $easeOutQuad 0.3s,
        transform $default $easeOutQuad 0.3s;

    .isInView & {
        opacity: 1;
        transform: translateY(0);
    }
}

.lockupEyebrow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    opacity: 0;
    transform: translateY(4rem);
    transition: opacity $default $easeOutQuad 0.2s,
        transform $default $easeOutQuad 0.2s;

    .isInView & {
        opacity: 1;
        transform: translateY(0);
    }
}

.svgContainerBefore {
    svg {
        width: 3.1rem;
    }
}

.svgContainerAfter {
    svg {
        width: 3.3rem;
    }
}

.eyebrow {
    color: var(--colors-blue);
    text-transform: uppercase;
    translate: 0 -0.2rem;
}

@include medium {
    .root {
        padding: var(--block-padding-side) var(--block-padding-side) 0
            var(--block-padding-side);
    }

    .inner .headline {
        max-width: 100rem;
        font-size: 8rem;
    }
}

@include large {
    .lockup {
        gap: 2.4rem;
    }

    .inner .eyebrow {
        font-size: 2.8rem;
    }

    .inner .headline {
        font-size: 12rem;
    }
}
