@use 'sass:math';

$size: 28px;
$width: 2px;
$height: 5px;

.spinner {
    position: relative;
    display: inline-block;
    width: $size;
    height: $size;
}

.spinner div {
    animation: spinner 1.2s linear infinite;
    transform-origin: math.div($size, 2) math.div($size, 2);
}

.spinner div:after {
    position: absolute;
    top: 50%;
    left: calc(50% + calc($width / -2));
    display: block;
    width: $width;
    height: $height;
    background: var(--spinner-color);
    border-radius: 20%;
    content: ' ';
}

.spinner div:nth-child(1) {
    animation-delay: -1.1s;
    transform: rotate(0deg);
}

.spinner div:nth-child(2) {
    animation-delay: -1s;
    transform: rotate(30deg);
}

.spinner div:nth-child(3) {
    animation-delay: -0.9s;
    transform: rotate(60deg);
}

.spinner div:nth-child(4) {
    animation-delay: -0.8s;
    transform: rotate(90deg);
}

.spinner div:nth-child(5) {
    animation-delay: -0.7s;
    transform: rotate(120deg);
}

.spinner div:nth-child(6) {
    animation-delay: -0.6s;
    transform: rotate(150deg);
}

.spinner div:nth-child(7) {
    animation-delay: -0.5s;
    transform: rotate(180deg);
}

.spinner div:nth-child(8) {
    animation-delay: -0.4s;
    transform: rotate(210deg);
}

.spinner div:nth-child(9) {
    animation-delay: -0.3s;
    transform: rotate(240deg);
}

.spinner div:nth-child(10) {
    animation-delay: -0.2s;
    transform: rotate(270deg);
}

.spinner div:nth-child(11) {
    animation-delay: -0.1s;
    transform: rotate(300deg);
}

.spinner div:nth-child(12) {
    animation-delay: 0s;
    transform: rotate(330deg);
}

@keyframes spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
