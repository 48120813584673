@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2.4rem;
}

.inner {
    display: flex;
    width: 100%;
    max-width: var(--tales-overlay-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        &:last-child {
            margin-bottom: 0;
        }

        .isBoldTheme & {
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
        }
    }
}

.copy {
    max-width: 28rem;
    color: var(--colors-blue);
    opacity: 0;
    text-align: center;
    transform: translateY(2rem);
    transition: opacity $default $easeOutQuad 0.1s,
        transform $default $easeOutQuad 0.1s;

    .isInView & {
        opacity: 1;
        transform: translateY(0);
    }

    .isAboveFold & {
        transition: opacity $default $easeOutQuad 0.4s,
            transform $default $easeOutQuad 0.4s;
    }
}

@include medium {
    .root {
        padding: 0 4.8rem;
    }

    .copy {
        max-width: 80rem;
    }
}

@include large {
    .inner {
        p {
            .isBoldTheme & {
                font-size: 3.2rem;
            }
        }
    }

    .copy {
        transform: translateY(3rem);
    }
}

@include xLarge {
}

@include xxLarge {
}
