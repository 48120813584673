@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    display: flex;
    width: 12rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
    margin-right: 4rem;
}

.label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
}

.checkbox {
    width: auto;
    margin: 1rem 0;
    color: var(--colors-cream);
    cursor: pointer;
    user-select: none;
}

.input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
}

.input:checked ~ .checkmark {
    background-color: var(--colors-cream);

    &::after {
        display: block;
    }
}

.checkmarkWrapper {
    position: relative;
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1.2rem;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border: 2px solid var(--colors-cream);
    border-radius: 2px;
    background-color: var(--colors-white);

    &::after {
        position: absolute;
        display: none;
        content: '';
    }
}

.checkbox .checkmark::after {
    top: 2px;
    left: 6px;
    width: 7px;
    height: 12px;
    border: solid var(--colors-blue);
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    transform: rotate(45deg);
}

@include medium {
    .root {
        width: auto;
        justify-content: flex-end;
        padding-left: 2.2rem;
        margin-right: 0;
    }
}
