@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2.4rem;
}

.inner {
    display: flex;
    width: 100%;
    max-width: var(--tales-overlay-max-width);
    flex-direction: column;
}

.media {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    opacity: 0;
    transform: translateY(4rem);
    transition: opacity $default $easeOutQuad var(--in-view-delay),
        transform $default $easeOutQuad var(--in-view-delay);

    .isInView & {
        opacity: 1;
        transform: translateY(0);
    }
}

.image {
    width: 100%;
    height: auto;

    .border & {
        border: var(--tales-overlay-img-border);
        box-shadow: var(--tales-overlay-img-shadow);
    }

    object-fit: contain;
}

.root .caption {
    color: var(--colors-blue);
    font-size: 1.2rem;
    text-align: center;
    text-wrap: pretty;
}

@include medium {
    .root {
        padding: 0 4.8rem;
    }
}

@include large {
    .media {
        gap: 2.4rem;
    }

    .image {
        .border & {
            border: var(--tales-overlay-img-border-large);
            box-shadow: var(--tales-overlay-img-shadow-large);
        }
    }

    .root .caption {
        font-size: 1.6rem;
    }
}
