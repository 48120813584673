@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    display: flex;

    width: 100%;
    flex-direction: column;
    padding-bottom: 4rem;
}

.select {
    width: 100%;
    padding: 1.2rem 0;
    border: 0;
    border-bottom: 1px solid rgba(color('blue'), 0.25);

    background-color: transparent;
    color: var(--colors-blue);

    &::placeholder {
        color: rgba(color('blue'), 0.4);
    }
}

@include large {
    .root {
        width: 19.5rem;
    }
}
