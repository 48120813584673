.root {
    position: relative;
}

.listWrapper {
    position: absolute;
    z-index: 2000;
    left: 0;
    min-width: 100%;
    box-sizing: border-box;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 30%);
    border-radius: 3px;
    margin-top: 2px;
    background: #fff;
    box-shadow: 0.05em 0.2em 0.6em rgba(0, 0, 0, 20%);
    text-shadow: none;

    > ul {
        max-height: 250px;
        padding: 0;
        margin: 0 !important;
        list-style: none;
        overflow-y: scroll;

        > li {
            position: relative;
            padding: 0.2em 0.5em;
            margin: 0 !important;
            cursor: pointer;
        }

        li[aria-selected='true'] {
            background: #e5e5e5;
        }
    }
}
