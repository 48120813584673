@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    overflow: hidden;
    height: 100%;
    flex: var(--listItem-flex);
    border-radius: 2rem;
    background-color: var(--colors-white);
    color: var(--colors-blue);

    &:last-child {
        margin-right: 0;
    }

    @media (hover: hover) {
        &:hover {
            .aspectBoxInner {
                scale: 1.04;
            }
        }
    }
}

.link {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    text-align: left;
}

.textBlock {
    display: flex;
    width: 100%;
    height: 8.2rem;
    flex-direction: column;
    padding: 1.8rem;

    &.date {
        justify-content: flex-start;
    }
}

.pageHeadlineWrapper {
    display: flex;
    justify-content: space-between;
    gap: 1.8rem;
}

.headlineIcon {
    flex-shrink: 0;
}

.pageSubHeadline {
    margin: 0;
    text-transform: uppercase;
}

.pageDate {
    width: 100%;
}

.pageHeadline {
    display: -webkit-box;
    overflow: hidden;
    margin: 0;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
}

.aspectBox {
    position: relative;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 327 / 172;
    background-color: var(--colors-black);
}

.aspectBoxInner {
    width: 100%;
    height: 100%;
    transition: scale $slow $easeOutCubic;
}

.image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@include medium {
    .aspectBox {
        aspect-ratio: 408 / 215;
    }

    .pageHeadlineWrapper {
        width: 100%;
    }

    .pageHeadline {
        width: 100%;

        --pageHeadline-margin: 1.6rem 0;
    }

    .textBlock {
        height: 11rem;
        padding: 2.4rem;
    }

    .listItem {
        --listItem-margin: 0 4.8rem 0 0;
        --listItem-flex: 1 50%;
    }
}

.emptyIcon {
    @include spread;

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--colors-blue);
    color: var(--colors-cream);

    > span {
        width: 24%;
        height: 30%;
    }
}
