@import 'styles/mixins';
@import 'styles/colors';

.root {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner {
    --gridColumnAreas: 'a';

    display: grid;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
    grid-auto-columns: 1fr;
    grid-gap: 1.2rem;
    grid-template-areas: var(--gridColumnAreas);
    list-style: none;
}

.question {
    width: 100%;
    color: var(--colors-blue);
}

.questionIcon {
    @include flex-center;

    width: 2.8rem;
    height: 2.8rem;
    flex-shrink: 0;
    border-radius: 50%;
    margin-left: 1rem;
    background-color: var(--colors-blue);
    color: var(--colors-cream);

    svg {
        width: 0.8rem;
        height: 1.1rem;
        margin-left: 1px;
        color: var(--colors-cream);
    }
}

.questionLink {
    display: none;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 2.2rem 0;

    @include bottom-divider;

    &.show {
        display: flex;
    }
}
