@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner {
    --gridColumnAreas: 'a';

    display: grid;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
    grid-auto-columns: 1fr;
    grid-gap: 1.2rem;
    grid-template-areas: var(--gridColumnAreas);
    list-style: none;
}

.listItem {
    display: none;
    box-sizing: border-box;

    &.show {
        display: block;
    }

    &--no-results {
        position: relative;
        padding: var(--productList-padding, 1rem);
        color: var(--colors-blue);

        .listItem--no-results-filler {
            padding-top: 28.5%;
        }

        > span {
            position: absolute;
            top: 0;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
    }
}

.landscape {
    width: 100%;
    margin: 1.2rem 0 0;
}

@include medium {
    .inner {
        --gridColumnAreas: 'a a';
    }

    @include last-filler-item(2);
}

@include large {
    .inner {
        --gridColumnAreas: 'a a a';
    }

    @include last-filler-item(3);
}
