@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/colors';

.root {
    position: fixed;
    top: 0;
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: none;
    white-space: nowrap;
    writing-mode: vertical-rl;
}

.button {
    display: flex;
    overflow: hidden;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 0.55rem;
    border: 0;
    border-radius: 0 1rem 1rem 0;
    animation: reveal 0.4s 0.1s $bounceLight forwards;
    background-color: var(--colors-white);
    box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 20%);
    pointer-events: auto !important;
    transform: translateX(-100%);
    transform-origin: center left;
}

.label {
    margin: 0;
    color: var(--colors-blue);
    line-height: 100% !important;
    text-transform: uppercase;
}

.icon {
    width: 1.08rem;
    margin: 0.8rem 0 0;
}

@keyframes reveal {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(-2%);
    }
}
