@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform: translateY(100%);
    transition: transform 0.6s $bounceLight;

    &.isActive {
        z-index: 10001;
        pointer-events: auto;
        transform: translateY(0);
    }

    &.isOverlayNavUse {
        transition-delay: 0.6s;
    }

    &.isOverlayNavUse.isActive {
        transition-delay: 0s;
    }
}

.overflow {
    overflow: auto;
    width: 100%;
    height: 100%;
}

.inner {
    width: 100%;
    min-height: 200vh;
}

.blocks {
    display: flex;
    flex-direction: column;
    padding-bottom: 4.8rem;
    gap: 4.8rem;
}

.close {
    position: absolute;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--colors-blue);
    color: var(--colors-cream);
    cursor: pointer;

    svg {
        scale: 0.75;
    }
}

.nav {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 4.8rem 1rem;
    background-color: var(--colors-cream);
}

.navHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 0 4.8rem;
}

.root .navHeadline {
    color: var(--colors-blue);
    font-size: 2.4rem;
    text-align: center;
    text-transform: uppercase;
}

.navItems {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 1rem;
    list-style-type: none;

    li {
        overflow: hidden;
        width: 100%;
    }
}

.navItem {
    width: 100%;
}

.navItemInner {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 2rem 2.4rem;
    border-radius: 2rem;
    gap: 1.2rem;
}

.navItemImage {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100%;
}

.root .navItemTitle {
    color: var(--colors-blue);
    font-size: 2.4rem;
    line-height: 85%;
    text-transform: uppercase;
}

.navItemLockup {
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 1rem;
}

.footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--block-padding-side);
}

@include medium {
    .nav {
        padding: 0 var(--block-padding-side) var(--block-padding-side);
    }

    .navItems {
        max-width: var(--block-max-width);
        flex-direction: row;
        gap: 1.2rem;

        li {
            width: calc(100% / 3);
            height: 100%;
        }
    }

    .navItem {
        width: 100%;
        height: auto;
        aspect-ratio: 40.8/51;
    }

    .navHeader {
        padding: var(--block-padding-side) 0;
    }

    .root .navHeadline {
        font-size: 4.8rem;
    }

    .navItemInner {
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        gap: 2.4rem;

        picture {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            aspect-ratio: 328/225;
        }
    }

    .navItemImage {
        top: auto;
        bottom: 0;
        width: 100%;
        height: auto;
    }

    .navItemLockup {
        width: 100%;
        gap: 1.2rem;
    }

    .blocks {
        padding-bottom: var(--block-padding-side);
        gap: var(--block-padding-side);
    }
}

@include large {
    .root .navItemTitle {
        font-size: 3.2rem;
    }

    .navItemInner {
        gap: 3.2rem;
    }

    .close {
        top: 3rem;
        right: 3rem;
        width: 5rem;
        height: 5rem;

        svg {
            scale: 1.2;
        }
    }
}

@include xLarge {
    .navItemInner {
        padding: 3.2rem 3rem;
        gap: 4.8rem;
    }

    .root .navItemTitle {
        font-size: 4.2rem;
    }
}

@include xxLarge {
    .navItemInner {
        padding: 4.8rem 4rem;
        gap: 5.2rem;
    }

    .root .navItemTitle {
        font-size: clamp(4.8rem, 3.3vw, 5.4rem);
    }
}
