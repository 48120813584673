@import 'styles/breakpoints';
@import 'styles/animations';

$dur: 0.4s;

.root {
    position: relative;
    overflow: hidden;
    padding-bottom: 4.8rem;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;
}

.lockup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4.8rem 0 2.4rem;
    gap: 2.4rem;
    text-align: center;
}

.headline {
    line-height: 85%;
    text-transform: uppercase;
}

.headline,
.copy {
    color: var(--colors-blue);
}

.copy {
    max-width: 44.4rem;
    text-wrap: pretty;
}

.cardsScroll {
    width: 100%;
}

.cardsContainer {
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: 0 2.4rem;
    padding-bottom: 2rem;
}

.cardsContainerInner {
    display: flex;
}

.cards {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    gap: 1rem;
    list-style-type: none;
    user-select: none;
}

.card {
    position: relative;
    width: calc(100vw - 4.8rem);
    border-radius: 2rem;
    aspect-ratio: 327/ 440;
    clip-path: inset(
        0 0 0 0 round 2rem
    ); // ios safari can't render the image if this has overflow: hidden

    &.isInView {
        .cardsInView & {
            .cardEyebrow,
            .cardTitle,
            .cardCta,
            .cardImage {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
}

.cardInner {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 3.4rem 2.4rem;
}

.cardLockup {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.cardEyebrow {
    opacity: 0;
    transform: translateY(1rem);
    transition: opacity 0.3s $easeOutQuad 0s, transform 0.3s $easeOutQuad 0s;
}

.card .cardTitle {
    color: var(--colors-blue);
    font-size: 4.2rem;
    line-height: 85%;
    opacity: 0;
    text-align: left;
    text-transform: uppercase;
    transform: translateY(1rem);
    transition: opacity $dur $easeOutQuad 0.1s, transform $dur $easeOutQuad 0.1s;
}

.cardCta {
    margin-top: -0.5rem;
    opacity: 0;
    transform: translateY(1rem);
    transition: opacity $dur $easeOutQuad 0.2s, transform $dur $easeOutQuad 0.2s;
}

.cardImage {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    object-fit: contain;
    opacity: 0;
    pointer-events: none;
    transform: var(--image-transform);
    transform-origin: var(--image-origin);
    transition: opacity $dur $easeOutQuad 0.3s, transform $dur $easeOutQuad 0.3s;
}

@include medium {
    .root {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding-bottom: var(--block-padding-side);
    }

    .sticky {
        width: 100%;
    }

    .header {
        padding: 0;
    }

    .lockup {
        padding: var(--block-padding-side) 0;
    }

    .copy {
        max-width: 60rem;
    }

    .cardsContainer {
        display: flex;
        overflow: hidden;
        padding: 0 var(--block-padding-end);
        -webkit-overflow-scrolling: unset;
        scroll-padding-left: unset;
        scroll-snap-type: unset;

        &::-webkit-scrollbar-track {
            margin-inline: 2.4rem 2.4rem;
        }
    }

    .cardsContainerInner {
        max-width: var(--block-max-width);
    }

    .cards {
        gap: 1.2rem;
    }

    .card {
        width: calc(100vw - (var(--block-padding-end) * 2));
        max-width: var(--block-max-width);
        padding: var(--block-padding-end);
        aspect-ratio: 1248/600;
        scroll-snap-align: unset;
    }

    .cardInner {
        height: 100%;
        justify-content: center;
        padding: 0;
    }

    .cardImage {
        right: 0;
        left: auto;
        width: auto;
        height: 100%;
        object-fit: contain;
    }

    .card .cardTitle {
        margin-bottom: 2.4rem;
        font-size: 5.5rem;
    }
}

@include large {
    .lockup {
        gap: 4.8rem;
    }

    .copy {
        max-width: 70rem;
    }

    .cardLockup {
        gap: 3rem;
    }

    .card .cardTitle {
        margin-bottom: 3.7rem;
        font-size: 8rem;
    }

    .cardButton {
        margin-top: 2.8rem;
    }
}

@include xLarge {
    .card .cardTitle {
        font-size: 8rem;
    }
}

@media (min-width: 1600px) {
    .cardsContainer {
        justify-content: center;
    }
}
