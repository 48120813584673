@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    position: relative;
    width: 100%;
    padding: 0 2.4rem;
}

.inner {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    text-align: center;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        font-weight: bold;
    }
}

.eyebrow {
    margin-bottom: -1rem;
    text-transform: uppercase;
}

.eyebrow,
.headline,
.copy {
    color: var(--colors-blue);
}

.body {
    display: flex;
    width: 100%;
    max-width: 28rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(2rem);
    transition: opacity $default $easeOutQuad 0.2s,
        transform $default $easeOutQuad 0.2s;

    .isAboveFold & {
        transition: opacity $default $easeOutQuad 0.4s,
            transform $default $easeOutQuad 0.4s;
    }

    .isInView & {
        opacity: 1;
        transform: translateY(0);
    }
}

.headline {
    line-height: 85%;
    opacity: 0;
    text-transform: uppercase;
    transform: translateY(2rem);
    transition: opacity $default $easeOutQuad 0.1s,
        transform $default $easeOutQuad 0.1s;

    .isAboveFold & {
        transition: opacity $default $easeOutQuad 0.3s,
            transform $default $easeOutQuad 0.3s;
    }

    .isInView & {
        opacity: 1;
        transform: translateY(0);
    }
}

@include medium {
    .root {
        padding: 0 4.8rem;
    }

    .content {
        gap: 4.8rem;
    }

    .body,
    .headline {
        max-width: 80rem;
        transform: translateY(4rem);
    }

    .body {
        transform: translateY(3rem);
    }

    .eyebrow {
        margin-bottom: -1.6rem;
    }
}
