@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    display: flex;
    width: 100%;
    justify-content: center;
    pointer-events: none;

    button {
        position: absolute;
    }

    --toss-curve: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.inner {
    position: relative;
    width: 100%;
    max-width: 50rem;

    // better centering for larger phones
    @media (min-width: 430px) {
        left: clamp(0%, 3vw, 2.5rem);
    }
}

.shred {
    position: absolute;
    will-change: transform;

    --toss-time: 1.1s;

    .animate & {
        animation: toss var(--toss-time) var(--toss-curve) forwards;

        .shredImage {
            animation: rotate var(--toss-time) linear forwards;
        }
    }
}

.shredImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    will-change: rotate;
}

.shredA {
    bottom: var(--bottom, -20rem);
    left: -3%;
    width: 5.2rem;
    height: 8.6rem;

    --toss-height: calc(33rem * var(--velocity-multiplier));
    --rotate-start: calc(10deg * var(--rotation-multiplier));
    --rotate-in: 0deg;
    --rotate-out: calc(-25deg * var(--rotation-multiplier));
}

.shredB {
    bottom: var(--bottom, -20rem);
    left: -2%;
    width: 8.1rem;
    height: 10.6rem;

    --toss-height: calc(49rem * var(--velocity-multiplier));
    --rotate-start: calc(40deg * var(--rotation-multiplier));
    --rotate-in: 0deg;
    --rotate-out: calc(-30deg * var(--rotation-multiplier));
}

.shredC {
    bottom: var(--bottom, -20rem);
    left: 15%;
    width: 12rem;
    height: 17rem;

    --toss-height: calc(48rem * var(--velocity-multiplier));
    --rotate-start: calc(-45deg * var(--rotation-multiplier));
    --rotate-in: 0deg;
    --rotate-out: calc(25deg * var(--rotation-multiplier));
}

.shredD {
    bottom: var(--bottom, -20rem);
    left: 29%;
    width: 5.8rem;
    height: 8.5rem;

    --toss-height: calc(42rem * var(--velocity-multiplier));
    --rotate-start: 0deg;
    --rotate-in: calc(-7deg * var(--rotation-multiplier));
    --rotate-out: calc(-20deg * var(--rotation-multiplier));
}

.shredE {
    bottom: var(--bottom, -25rem);
    left: 19%;
    width: 11rem;
    height: 9.9rem;

    --toss-height: calc(35rem * var(--velocity-multiplier));
    --rotate-start: 0deg;
    --rotate-in: calc(-3deg * var(--rotation-multiplier));
    --rotate-out: calc(-15deg * var(--rotation-multiplier));
}

.shredF {
    bottom: var(--bottom, -35rem);
    left: 30%;
    width: 5.5rem;
    height: 11.6rem;

    --toss-height: calc(32rem * var(--velocity-multiplier));
    --rotate-start: calc(15deg * var(--rotation-multiplier));
    --rotate-in: 0deg;
    --rotate-out: calc(-35deg * var(--rotation-multiplier));
}

.shredG {
    bottom: var(--bottom, -20rem);
    left: 53%;
    width: 9.8rem;
    height: 7.4rem;

    --toss-height: calc(22rem * var(--velocity-multiplier));
    --rotate-start: calc(30deg * var(--rotation-multiplier));
    --rotate-in: 0deg;
    --rotate-out: calc(-10deg * (var(--rotation-multiplier) * 2));
}

.shredH {
    bottom: var(--bottom, -20rem);
    left: 68%;
    width: 5.2rem;
    height: 8.1rem;

    --toss-height: calc(28rem * var(--velocity-multiplier));
    --rotate-start: calc(-7deg * var(--rotation-multiplier));
    --rotate-in: 0deg;
    --rotate-out: calc(15deg * var(--rotation-multiplier));
}

.shredI {
    bottom: var(--bottom, -20rem);
    left: 71%;
    width: 9.2rem;
    height: 11.8rem;

    --toss-height: calc(36rem * var(--velocity-multiplier));
    --rotate-start: calc(10deg * var(--rotation-multiplier));
    --rotate-in: 0deg;
    --rotate-out: calc(-15deg * var(--rotation-multiplier));
}

.shredJ {
    bottom: var(--bottom, -20rem);
    left: 65%;
    width: 8.2rem;
    height: 4.7rem;

    --toss-height: calc(50rem * var(--velocity-multiplier));
    --toss-time: 1.17s;
    --rotate-start: calc(-5deg * var(--rotation-multiplier));
    --rotate-in: 0deg;
    --rotate-out: calc(15deg * var(--rotation-multiplier));
}

.shredK {
    bottom: var(--bottom, -20rem);
    left: 81%;
    width: 8rem;
    height: 10rem;

    --toss-height: calc(56rem * var(--velocity-multiplier));
    --rotate-start: calc(10deg * var(--rotation-multiplier));
    --rotate-in: 0deg;
    --rotate-out: calc(-75deg * (var(--rotation-multiplier) / 2));
}

.shredL,
.shredM,
.shredN,
.shredO {
    display: none;
}

@include medium {
    .inner {
        left: 0;
        max-width: var(--block-max-width);
    }

    .shred {
        scale: 0.8;
        translate: -25% -15%;
    }

    .shredE {
        bottom: var(--bottom, -30rem);
        left: -2%;
        width: 15.6rem;
        height: 14rem;

        --toss-height: calc(42rem * var(--velocity-multiplier));
    }

    .shredD {
        left: 3%;
        width: 8.1rem;
        height: 12.1rem;

        --toss-time: 1.15s;
        --toss-height: calc(47rem * var(--velocity-multiplier));
    }

    .shredH {
        left: 8%;
        width: 7.4rem;
        height: 11.5rem;

        --toss-height: calc(52rem * var(--velocity-multiplier));
    }

    .shredC {
        bottom: var(--bottom, -25rem);
        left: 19%;
        width: 14.7rem;
        height: 19.2rem;

        --toss-time: 1.17s;
        --toss-height: calc(53rem * var(--velocity-multiplier));
    }

    .shredA {
        left: 24%;
        width: 8rem;
        height: 13rem;

        --toss-time: 1.13s;
        --toss-height: calc(32rem * var(--velocity-multiplier));
    }

    .shredG {
        left: 29%;
        width: 14.3rem;
        height: 13rem;

        --toss-height: calc(38rem * var(--velocity-multiplier));
    }

    .shredB {
        left: 38%;
        width: 15rem;
        height: 15.4rem;

        --toss-height: calc(58rem * var(--velocity-multiplier));
    }

    .shredL {
        bottom: var(--bottom, -20rem);
        left: 41%;
        display: block;
        width: 15.8rem;
        height: 15.4rem;

        --toss-height: calc(40rem * var(--velocity-multiplier));
        --rotate-start: calc(-5deg * var(--rotation-multiplier));
        --rotate-in: 0deg;
        --rotate-out: calc(20deg * var(--rotation-multiplier));
    }

    .shredJ {
        left: 49%;
        display: block;
        width: 12.5rem;
        height: 8rem;

        --toss-time: 1.17s;
        --toss-height: calc(54rem * var(--velocity-multiplier));
    }

    .shredI {
        bottom: var(--bottom, -22rem);
        left: 64%;
        width: 13rem;
        height: 17rem;

        --toss-height: calc(52rem * var(--velocity-multiplier));
    }

    .shredM {
        bottom: var(--bottom, -30rem);
        left: 65%;
        display: block;
        width: 13rem;
        height: 10rem;

        --toss-height: calc(44rem * var(--velocity-multiplier));
        --rotate-start: calc(-5deg * var(--rotation-multiplier));
        --rotate-in: 0deg;
        --rotate-out: calc(10deg * var(--rotation-multiplier));
    }

    .shredK {
        left: 76%;
        width: 12rem;
        height: 13rem;

        --toss-time: 1.13s;
        --toss-height: calc(41rem * var(--velocity-multiplier));
    }

    .shredN {
        bottom: var(--bottom, -38rem);
        left: 84%;
        display: block;
        width: 18rem;
        height: 21rem;

        --toss-height: calc(47rem * var(--velocity-multiplier));
        --rotate-start: calc(-10deg * var(--rotation-multiplier));
        --rotate-in: 0deg;
        --rotate-out: calc(15deg * var(--rotation-multiplier));
    }

    .shredF {
        bottom: var(--bottom, -22rem);
        left: 86%;
        width: 8rem;
        height: 17rem;

        --toss-height: calc(53rem * var(--velocity-multiplier));
    }

    .shredO {
        bottom: var(--bottom, -22rem);
        left: 97%;
        display: block;
        width: 11rem;
        height: 10rem;

        --toss-height: calc(47rem * var(--velocity-multiplier));
        --rotate-start: calc(25deg * var(--rotation-multiplier));
        --rotate-in: 0deg;
        --rotate-out: calc(-35deg * var(--rotation-multiplier));
    }
}

@include xLarge {
    .shred {
        scale: 1;
        translate: -20% 0;
    }
}

@include xxLarge {
    .shred {
        translate: 0 0;
    }
}

@keyframes toss {
    0% {
        animation-timing-function: linear;
        transform: translateY(var(--bottom));
    }

    27% {
        transform: translateY(calc(var(--toss-height) * -1));
    }

    100% {
        transform: translateY(var(--bottom));
    }
}

@keyframes rotate {
    0% {
        animation-timing-function: linear;
        transform: rotate(var(--rotate-start));
    }

    27% {
        transform: rotate(var(--rotate-in));
    }

    100% {
        transform: rotate(var(--rotate-out));
    }
}
