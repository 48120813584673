@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 1.2rem;
    border-bottom: 0.2rem solid color('blue');
    margin-bottom: 2.4rem;

    &.isLoading {
        border-bottom: 0.2rem solid rgba(color('blue'), 0.15);
    }
}

.headline {
    color: var(--colors-blue);
    text-transform: uppercase;
}

.subhead {
    align-self: flex-end;
    margin-left: 1.2rem;
    text-transform: none;
}

.loading {
    span {
        display: inline-block;
        animation: blink 1.4s infinite both;
    }

    span:nth-child(1) {
        animation-delay: 0s;
    }

    span:nth-child(2) {
        animation-delay: 0.2s;
    }

    span:nth-child(3) {
        animation-delay: 0.4s;
    }
}

@keyframes blink {
    0%,
    80%,
    100% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }
}

@include medium {
    .root {
        padding-bottom: 2.4rem;
        margin-bottom: 4.8rem;
    }

    .subhead {
        margin-left: 2.4rem;
    }
}

@keyframes fade-up {
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
