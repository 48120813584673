.root {
    max-width: 23em;
    color: #d3d3d3;
    font-size: 1.2rem;
    text-wrap: balance;

    @media (prefers-contrast: more) {
        color: var(--colors-black);
    }
}
