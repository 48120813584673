@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/colors';

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.root {
    --spinner-color: var(--colors-blue);

    position: fixed;
    z-index: var(--z-modal);
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: transparent;

    &::backdrop {
        animation: 1s fade;
        background: rgba(0, 0, 0, 40%);
    }

    &[open] {
        display: flex;

        .alert {
            animation: scale 0.6s $bounceLight forwards;
        }
    }

    &.isClosing {
        .alert {
            animation: close 0.6s $bounceLight forwards;
        }
    }

    &.isMinimized {
        pointer-events: none;

        .alert {
            animation: close 0.6s $bounceLight forwards;
        }
    }

    &.isUserStoreMinimized {
        pointer-events: none;

        .alert {
            animation: none;
            transform: scale(0);
        }
    }
}

.alert {
    position: relative;
    display: grid;
    overflow: clip;
    max-width: 100rem;
    border-radius: 2rem;
    margin: 3.2rem;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 30%);
    color: var(--colors-blue);
    grid-template-rows: auto auto;

    .isMinimized & {
        animation: close 0.6s cubic-bezier(0.36, 0, 0.66, -0.56) forwards;
    }
}

.form {
    position: relative;
    z-index: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;
    background: white;
}

.image {
    position: relative;
}

.backgroundImage {
    object-fit: cover;
}

.root .closeButton {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
}

.closeIconBuffer {
    padding: 1rem;
}

.closeIconWrapper {
    --size: 2.8rem;

    position: relative;
    width: var(--size);
    height: var(--size);
    border-radius: calc(var(--size) / 2);
    background: var(--colors-cream);
    cursor: pointer;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.icon {
    position: relative;
    height: 4rem;
    margin-bottom: 1rem;
}

.headlineWrapper {
    margin-bottom: 2.4rem;
    text-align: center;
    text-transform: uppercase;
}

.copyWrapper {
    margin: 1rem 0 2rem;
    text-align: center;
}

.copyWrapper .copy {
    line-height: 1.3;
}

.link {
    color: var(--colors-orange);
    font-weight: bold;
}

.inputs {
    display: flex;
    width: 100%;
    max-width: 30rem;
    flex-direction: column;
    align-items: center;
}

.inputLockup {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.inputWrap {
    width: 100%;
}

.input {
    width: 100%;
    min-height: 4.8rem;
    padding: 0 0.6rem 0 1.6rem;
    border: 0;
    border-radius: 3rem;
    background-color: var(--colors-cream);
    color: var(--colors-blueDark);
    font-size: 1.6rem;

    &::placeholder {
        color: rgba(color('blue'), 0.4);
        font-weight: 500;
        opacity: 1;
    }

    &:focus {
        border: 0;
        outline: 0;
    }

    &.inputError {
        border: 1px solid var(--colors-cheeseSharpCheddar);
    }

    .inputSuccess & {
        pointer-events: none;

        &::placeholder {
            color: var(--colors-cheeseMexican4Cheese);
        }
    }
}

.buttonSpinner {
    position: absolute;
    left: 100%;
    margin-left: 1rem;
    opacity: 0;
    pointer-events: none;
}

.submitButtonContainer {
    position: relative;
    display: flex;
    align-items: center;

    .submitButton__isLoading {
        opacity: 0;
    }

    .buttonSpinner__isLoading {
        opacity: 1;
    }
}

.submitButton {
    margin: 1.2rem 0;

    .inputSuccess & {
        display: none;
    }
}

.checkboxWrapper {
    max-width: 22rem;
    margin: 1.2rem 0 0 2.4rem;

    &.hide {
        display: none;
    }
}

.checkbox {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 0.8rem;
    cursor: pointer;
    text-align: left;
    text-wrap: wrap;
    user-select: none;

    a {
        text-decoration: underline;
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
    }

    input:checked ~ .checkmark {
        background-color: var(--colors-white);

        &::after {
            display: block;
        }
    }
}

.checkboxWrapper .checkbox {
    font-size: 1.2rem;
    line-height: 1.3;
}

.checkboxError {
    color: var(--colors-cheeseSharpCheddar);
}

.checkmark {
    position: absolute;
    top: 0;
    left: -2rem;
    width: 1.8rem;
    height: 1.8rem;
    border: none;
    border: 2px solid var(--colors-blue);
    border-radius: 2px;
    background-color: var(--colors-white);

    &::after {
        position: absolute;
        display: none;
        content: '';
    }
}

.checkbox .checkmark::after {
    top: 1px;
    left: 4px;
    width: 6px;
    height: 10px;
    border: solid var(--colors-blue);
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    transform: rotate(45deg);
}

.errors {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1.8rem;
    border-radius: 2rem 2rem 0 0;
    background-color: pink;
    background-color: color-mix(
        in srgb,
        var(--colors-cheeseSharpCheddar),
        white 80%
    );
    color: white;
}

.joinError {
    position: relative;
    left: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0;
    margin: 0;
    color: var(--colors-cheeseSharpCheddar);
    list-style: none;
    text-align: center;
}

.success {
    @include spread;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.6rem 2.4rem;
    background-color: var(--colors-white);
    opacity: 0;
    pointer-events: none;

    .copyWrapper {
        margin: 1rem 0 0;
    }
}

.success .successHeadline {
    display: block;
    font-size: 2.8rem;
}

.success .successCopy {
    font-size: 1.6rem;
}

.successComplete {
    opacity: 1;
    pointer-events: auto;
}

.successIcon {
    margin-bottom: 1.6rem;
}

.zipcodeInput {
    margin-top: 0.8rem;
}

.reCaptchaLegal {
    > div {
        color: rgba(color('blue'), 0.4);
        font-size: 1rem;
        text-align: center;
    }
}

@keyframes scale {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes close {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}

@include medium {
    .alert,
    .success {
        width: 100%;
        max-width: 100rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }

    .form {
        height: 100%;
        padding: 4.5rem;
    }

    .closeIconWrapper {
        background-color: var(--colors-blue);
        color: var(--colors-white);
    }

    .backgroundImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left top;
    }

    .closeIconBuffer {
        padding: 2rem;
    }

    .icon {
        height: 5rem;
        margin-bottom: 1.6rem;
    }

    .inputs {
        display: flex;
        width: 100%;
    }

    .zipcodeInput {
        margin-top: 1rem;
    }

    .submitButton {
        margin: 2.4rem 0;
    }

    .copyWrapper {
        margin: 1.6rem 0 2.4rem;
    }

    .checkboxWrapper {
        max-width: 27.7rem;
        margin-top: 1.6rem;
    }

    .checkbox {
        padding-left: 1.2rem;
    }

    .checkmark {
        width: 2.2rem;
        height: 2.2rem;
    }

    .checkbox .checkmark::after {
        top: 2px;
        left: 6px;
        width: 7px;
        height: 12px;
    }

    .success .successHeadline {
        font-size: 3rem;
    }

    .errors {
        min-height: 9rem;
        padding: 2.4rem;
    }
}
