@import 'styles/animations';

.root {
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: opacity $slow $easeOutCubic;
    will-change: opacity;

    &.isMounted {
        opacity: 1;
    }
}

.inner {
    position: absolute;
    top: calc(var(--distance) * -1);
    left: 0;
    width: 100%;
    height: calc(100% + var(--distance));
}
