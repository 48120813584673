@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 2.4rem;
}

.inner {
    display: flex;
    width: 100%;
    max-width: var(--tales-overlay-max-width);
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
}

.media {
    position: relative;
    width: 100%;
    aspect-ratio: 328/256;
    cursor: pointer;
    transition: opacity 0.3s $easeOutQuad 0.3s;

    .isLoading & {
        opacity: 0;
    }
}

.isInteractive.isHovering .media {
    .mediaItem .image {
        transform: translate(-4%, -2%) rotate(-2deg);
    }

    .mediaItem.isActive .image {
        transform: translate(4%, -2%) rotate(2deg);
    }
}

.mediaItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 85%;
    aspect-ratio: 27/22;

    .isInteractive & {
        animation: move-rotate-inactive 0.6s forwards linear;

        &.isActive {
            animation: move-rotate-active 0.6s forwards linear;
        }
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s $easeOutQuad;

    .border & {
        border: var(--tales-overlay-img-border-medium);
        box-shadow: var(--tales-overlay-img-shadow);
    }
}

.root .caption {
    position: relative;
    width: 100%;
    max-width: 80rem;
    color: var(--colors-blue);
    font-size: 1.2rem;
    text-align: center;
    text-wrap: pretty;
}

.captionItem {
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s $easeOutQuad;

    &.isActive {
        opacity: 1;
        transition: opacity 0.3s $easeOutQuad 0.3s;
    }
}

@include medium {
    .root {
        padding: 0 4.8rem;
    }
}

@include large {
    .inner {
        gap: 2.4rem;
    }

    .root .caption {
        font-size: 1.6rem;
    }

    .image {
        .border & {
            border: var(--tales-overlay-img-border-large);
            box-shadow: var(--tales-overlay-img-shadow-large);
        }
    }
}

@keyframes move-rotate-inactive {
    0% {
        z-index: 2;
        animation-timing-function: ease-in-out;
        transform: translate(17.5%, 0) rotate(0deg);
    }

    49.9% {
        z-index: 2;
        animation-timing-function: ease-in-out;
    }

    50% {
        z-index: 1;
        animation-timing-function: ease-in-out;
        transform: translate(70%, -15%) rotate(15deg);
    }

    100% {
        z-index: 1;
        animation-timing-function: ease-in-out;
        transform: translate(0, 11.5%) rotate(0deg);
    }
}

@keyframes move-rotate-active {
    0% {
        z-index: 1;
        animation-timing-function: ease-in-out;
        transform: translate(0, 11.5%) rotate(0deg);
    }

    49.9% {
        z-index: 1;
        animation-timing-function: ease-in-out;
    }

    50% {
        z-index: 2;
        animation-timing-function: ease-in-out;
        transform: translate(-60%, 15%) rotate(-20deg);
    }

    100% {
        z-index: 2;
        animation-timing-function: ease-in-out;
        transform: translate(17.5%, 0) rotate(0deg);
    }
}

@keyframes move-rotate-inactive-from-hover {
    0% {
        z-index: 2;
        animation-timing-function: ease-in-out;
        transform: translate(17.5%, 0) rotate(0deg);
    }

    49.9% {
        z-index: 2;
        animation-timing-function: ease-in-out;
    }

    50% {
        z-index: 1;
        animation-timing-function: ease-in-out;
        transform: translate(70%, -15%) rotate(15deg);
    }

    100% {
        z-index: 1;
        animation-timing-function: ease-in-out;
        transform: translate(0, 11.5%) rotate(0deg);
    }
}

@keyframes move-rotate-active-from-hover {
    0% {
        z-index: 1;
        animation-timing-function: ease-in-out;
        transform: translate(0, 11.5%) rotate(0deg);
    }

    49.9% {
        z-index: 1;
        animation-timing-function: ease-in-out;
    }

    50% {
        z-index: 2;
        animation-timing-function: ease-in-out;
        transform: translate(-60%, 15%) rotate(-20deg);
    }

    100% {
        z-index: 2;
        animation-timing-function: ease-in-out;
        transform: translate(17.5%, 0) rotate(0deg);
    }
}
