@import 'styles/breakpoints';
@import 'styles/fonts';
@import 'styles/colors';

.root {
    --spacer: 2.4rem;
    --mask-height: 1.5rem;
    --spinner-color: var(--colors-blue);

    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--root-padding, var(--block-padding-end) 2.4rem);
    background: color('creamHeavy');
    color: var(--colors-blue);
}

.inner {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    min-height: min(80rem, 35vw);
    flex-direction: var(--inner-flex-direction, column);
    align-items: center;
    justify-content: center;
}

.contentWrapper {
    z-index: 11;
    display: flex;
    width: 100%;
    max-width: 60rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.eyebrow,
.headline {
    max-width: 57rem;
    text-align: left;
    text-transform: uppercase;
}

.eyebrow {
    font-family: $GTWalsheimUltraBold;
    font-size: var(--eyebrow-font-size, 3rem);
    font-weight: bold;
    line-height: var(--eyebrow-line-height, 2.55rem);
}

.headline {
    font-family: $GTWalsheimUltraBold;
    font-size: var(--headline-font-size, 5rem);
    font-weight: bold;
    line-height: var(--headline-line-height, 4.25rem);
}

.copyWrapper {
    max-width: 47.4rem;
    margin: var(--spacer) 0;
}

.description {
    margin: var(--description-margin, 0);
}

.listLabel {
    text-transform: uppercase;
}

.list {
    padding: 0 0 0 2.4rem;
    margin: 1rem 0 0;
}

.deckWrapper {
    width: 100%;
    margin-top: 4rem;
}

.listWrapper {
    margin-top: var(--list-wrapper-margin-top, var(--spacer));
}

.form {
    width: 100%;
}

.checkbox {
    position: relative;
    display: block;
    width: 100%;
    max-width: 42rem;
    padding-left: 2rem;
    margin-top: 1.7rem;
    margin-bottom: 1rem;
    margin-left: 1.4rem;
    cursor: pointer;
    text-align: left;
    user-select: none;

    &-error {
        color: var(--colors-cheeseSharpCheddar);
    }

    a {
        text-decoration: underline;
    }

    label {
        margin: 0;
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
    }

    input:checked ~ .checkmark {
        &::after {
            display: block;
        }
    }
}

.checkmark {
    position: absolute;
    top: 0.5rem;
    left: -2rem;
    width: 2.2rem;
    height: 2.2rem;
    border: 0.2rem solid var(--colors-blue);
    border-radius: 0.5rem;

    &::after {
        position: absolute;
        display: none;
        content: '';
    }
}

.checkbox .checkmark::after {
    top: 2px;
    left: 6px;
    width: 7px;
    height: 12px;
    border: solid var(--colors-blue);
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    transform: rotate(45deg);
}

.inputLockup {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 47rem;
    height: var(--input-height, 6.4rem);
    align-items: center;
    justify-content: center;
}

.inputWrap {
    width: 100%;
    height: 100%;
}

.input {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 1rem 8rem 1rem 2rem;
    border: none;
    border-radius: 29rem;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    font-weight: 500;

    &::placeholder {
        color: var(--colors-blue);
        font-weight: 500;
    }

    .inputSuccess & {
        color: var(--colors-cheeseMexican4Cheese);
        pointer-events: none;
    }
}

.buttonSpinner {
    position: absolute;
    top: 55%;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);
}

.submitButtonWrap {
    position: absolute;
    right: 2rem;
    display: flex;
    width: 4rem;
    height: 4rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: transform 0.25s;
    will-change: transform;

    &:hover {
        transform: scale(1.1);
    }

    &__isLoading {
        .submitButton {
            opacity: 0;
        }

        .buttonSpinner {
            opacity: 1;
        }
    }

    &__isSuccess {
        opacity: 0;
    }
}

.reCaptchaLegal {
    margin: 1.4rem;

    > div {
        color: rgba(color('blue'), 0.25);
    }
}

.submitButton {
    display: inline;
    overflow: visible;
    padding: 0;
    border: 0;
    margin: 0;
    appearance: none;
    background: transparent;
    color: inherit;
    font: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    line-height: normal;
    outline: none;
    text-align: left;

    .inputSuccess & {
        display: none;
    }
}

.joinError {
    color: var(--colors-cheeseSharpCheddar);
}

@include medium {
    .root {
        --root-padding: 8rem var(--block-padding-side);
    }

    .eyebrow {
        max-width: none;
    }
}

@include xLarge {
    .root {
        --inner-flex-direction: row;
        --eyebrow-font-size: 3.6rem;
        --eyebrow-line-height: 3.6rem;
        --headline-font-size: 8rem;
        --headline-line-height: 7.48rem;
        --copy-wrapper-flex-direction: row;
        --list-wrapper-margin-top: 0;
        --description-margin: 0 0 2.6rem 0;
    }

    .deckWrapper {
        width: 50%;
        margin-top: 0;
        margin-bottom: 4rem;

        section {
            --root-padding: 0 0 4rem;
        }

        figure {
            --root-width: 75%;
        }
    }
}

@include xxLarge {
    .root {
        --root-padding: var(--block-padding);
    }

    .deckWrapper {
        section {
            max-height: 75rem;
        }
    }
}
