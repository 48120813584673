@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    display: flex;
    flex-direction: column;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
}

.bestByFields {
    display: flex;

    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.bestByFieldItem:not(:first-child) {
    margin: 0 0 0 1rem;
}

.select {
    width: 100%;
    padding: 1.2rem 0;
    border: 0;
    border-bottom: 1px solid rgba(color('blue'), 0.25);

    background-color: transparent;
    color: var(--colors-blue);

    &::placeholder {
        color: rgba(color('blue'), 0.4);
    }

    &.isDefault {
        color: rgba(color('blue'), 0.4);
    }
}
