@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

.root {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 4.8rem 0;
    color: var(--colors-blue);
    text-align: center;

    &::before {
        position: absolute;
        bottom: 0;
        left: 2.4rem;
        width: calc(100% - 4.8rem);
        border-bottom: 0.1rem solid rgba(color('blue'), 0.25);
        content: '';
    }
}

.textContainer {
    padding: 0 2.4rem;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 6rem;
    margin-bottom: 1.2rem;

    svg {
        width: auto;
    }
}

.title {
    margin: 0;
    text-transform: uppercase;
}

.copy {
    margin: 1.2rem 0 0;

    p {
        text-wrap: pretty;
    }
}

.cardsContainer {
    width: 100%;
    margin-top: 4.8rem;
}

.carousel {
    --slide-offset: 2.4rem;
    --slide-spacing: 1.2rem;

    @include carousel-scrollbar-offset(
        var(--slide-offset),
        var(--slide-offset)
    );

    // center card in mobile if there is only one
    .isSingleCard & {
        --slide-offset: 8.5vw;

        // remove centering padding when card is at max width
        @media (min-width: 494px) {
            --slide-offset: 2.4rem;
        }
    }
}

.card {
    @include carousel-gutters(var(--slide-offset));
}

.scrollAnchor {
    position: absolute;
    top: 0;
}

@include large {
    .root {
        --inner-offset: 10.8rem;

        padding: 9.6rem 0;
        text-align: left;

        &:first-child {
            padding-top: 0;

            .scrollAnchor {
                top: -9.6rem;
            }
        }

        &::before {
            left: 4.8rem;
            width: calc(100% - var(--inner-offset));
        }
    }

    .inner {
        margin-left: 4.8rem;
    }

    .icon {
        height: 6.4rem;
        margin: 0 2.4rem 0 0;
    }

    .textContainer {
        padding: 0;
    }

    .titleContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .carousel {
        --slide-offset: 0;
        --nav-prev-left: -3rem;

        .isSingleCard & {
            --slide-offset: 0;
        }

        @include carousel-scrollbar-offset(0, var(--block-padding-side));
    }

    .card {
        &:last-of-type > * {
            padding-right: 0;
            margin-right: var(--block-padding-side);
        }
    }
}

@include xLarge {
    .root {
        --inner-offset: 19.2rem;

        &::before {
            left: 9.6rem;
        }
    }

    .inner {
        margin-left: 9.6rem;
    }

    .copy {
        width: 100%;
        max-width: 71.4rem;
    }
}

@include blockMaxWidthPlusPadding {
    .root {
        --inner-offset: calc(
            ((100vw - var(--block-max-width)) / 2) + var(--block-padding-side)
        );
    }
}
