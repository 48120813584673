@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/animations';

.root {
    width: 100%;
}

.grid {
    --grid-template: repeat(1, 1fr);

    display: grid;
    width: 100%;
    max-width: var(--block-max-width);
    margin: 0 auto;
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
}

.campaignForm {
    flex-direction: column;
    background-color: var(--colors-white);
    color: var(--color);
}

.full-width,
.half-width {
    grid-column: 1/-1;
}

.fieldWrapper {
    display: flex;
    flex-direction: column;
    text-align: left;

    label {
        text-transform: uppercase;
    }

    &::after {
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--color);
        content: '';
        opacity: 0.25;
    }
}

.field {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
}

.input {
    color: var(--color);

    &::placeholder {
        color: var(--color);
        opacity: 0.4;
    }
}

.input,
.select {
    width: 100%;
    padding: 1.2rem 0;
    border: 0;
    border-radius: 0;
}

.select {
    background-color: var(--colors-white);
    color: var(--color);

    &.isDefault {
        color: rgba(color('blue'), 0.5);
    }
}

.hasError {
    border-color: var(--colors-cheeseSharpCheddar);
}

.error {
    color: var(--colors-cheeseSharpCheddar);
    letter-spacing: 0;
    text-transform: none;
}

.button {
    max-width: fit-content;
    margin-top: 3rem;
}

@include medium {
    .half-width {
        grid-column: span 1;
    }
}
