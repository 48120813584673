@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4.8rem;
    background-color: rgba(0, 30, 96, 0.05);
    border-radius: 2rem;
    text-align: center;
}

.visitUs {
    .heading {
        padding: 0 2rem 2.4rem;
    }
}

.button {
    max-width: 13rem;
    border-radius: 0.6rem;
}

.links {
    display: flex;
    flex-direction: column;
}

.compliment {
    .heading {
        padding-bottom: 4.8rem;
    }
}

.inner {
    display: flex;
    width: 100%;
    max-width: 35rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    a {
        padding-right: 1rem;

        &:last-child {
            padding-right: 0;
        }
    }

    svg {
        height: 2.25rem;
    }
}

.label {
    padding-bottom: 2rem;
    text-transform: uppercase;
}

@include medium {
    .button {
        max-width: 17rem;
        border-radius: 0.8rem;
    }
}
