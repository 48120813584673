@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2.4rem;
}

.inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 4.8rem;
}

.media {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2.4rem;
    gap: 1.2rem;
    opacity: 0;
    transform: translateY(4rem);
    transition: opacity $default $easeOutQuad 0.1s,
        transform $default $easeOutQuad 0.1s;

    .isInView & {
        opacity: 1;
        transform: translateY(0);
    }
}

.image {
    width: 100%;
    height: auto;

    .border & {
        border: var(--tales-overlay-img-border);
        box-shadow: var(--tales-overlay-img-shadow);
    }

    object-fit: contain;
}

.inner .caption {
    color: var(--colors-blue);
    font-size: 1.2rem;
    text-align: center;
    text-wrap: pretty;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    opacity: 0;
    text-align: center;
    transform: translateY(4rem);
    transition: opacity $default $easeOutQuad 0.2s,
        transform $default $easeOutQuad 0.2s;

    &::after,
    &::before {
        display: block;
        width: 2.7rem;
        height: 0.4rem;
        background-color: var(--colors-blue);
        content: '';
    }

    .isInView & {
        opacity: 1;
        transform: translateY(0);
    }
}

.inner .copy {
    max-width: 28rem;
    color: var(--colors-blue);
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 2.6rem */
}

@include medium {
    .root {
        padding: 0 4.8rem;
    }

    .inner {
        max-width: var(--tales-overlay-max-width);
        flex-direction: row;
    }

    .content {
        width: 50%;
    }

    .media {
        width: 50%;
        padding: 0;
    }

    .inner .copy {
        max-width: 46rem;
    }
}

@include large {
    .content {
        gap: 4.2rem;
    }

    .media {
        gap: 2.4rem;
    }

    .inner .caption {
        font-size: 1.6rem;
    }

    .inner .copy {
        font-size: 3.2rem;
    }

    .image {
        .border & {
            border: var(--tales-overlay-img-border-medium);
            box-shadow: var(--tales-overlay-img-shadow-large);
        }
    }
}
