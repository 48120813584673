@import 'styles/breakpoints';

.root {
    --color: var(--colors-blue);

    position: relative;
    width: 100%;
    border-radius: 3rem;
    background-color: var(--colors-white);
    color: var(--color);
}

.header {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--block-padding-end);
    text-align: center;

    p {
        margin: 0;
    }
}

.eyebrow,
.headline {
    text-transform: uppercase;
}

.eyebrow {
    padding-bottom: 2rem;
}

.headline {
    padding-bottom: 2.4rem;
}

.checkbox {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 2rem;
    color: var(--color, currentcolor);
    cursor: pointer;
    text-align: left;
    user-select: none;

    a {
        text-decoration: underline;
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
    }

    input:checked ~ .checkmark {
        background-color: var(--color, currentcolor);

        &::after {
            display: block;
        }
    }
}

.label {
    p {
        margin: 0;
    }
}

.checkmark {
    position: absolute;
    top: -0.1em;
    left: -2rem;
    width: 2.2rem;
    height: 2.2rem;
    border: 2px solid var(--color, currentcolor);
    border-radius: 2px;
    background-color: var(--colors-white);

    &::after {
        position: absolute;
        display: none;
        content: '';
    }

    .isFocus & {
        outline: 0.2rem solid blue;
    }
}

.checkbox .checkmark::after {
    top: 2px;
    left: 6px;
    width: 7px;
    height: 12px;
    border: solid var(--colors-cream);
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    transform: rotate(45deg);
}

.optInsAndSubmit {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 4.8rem auto 0;
}

.optIns {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.optIn {
    margin-bottom: 0;
}

.alerts {
    margin: 4.8rem auto 0;
}

.submitWrapper {
    position: relative;
}

.submit {
    width: auto;
    margin-top: 4.8rem;

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.submitSpinner {
    --spinner-color: var(--colors-blue);

    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    margin-left: 1rem;
}

.rules {
    display: block;
    margin: 4.8rem auto 0;
    text-align: center;

    a {
        text-decoration: underline;
    }

    div {
        max-width: none;
    }
}

@include large {
    .header {
        p {
            padding: 0 5.35rem;
            margin: 0;
        }
    }

    .optInsAndSubmit {
        display: flex;
        width: 100%;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        margin-top: 2.4rem;
    }

    .optIn {
        padding-right: 3.2rem;
        margin-bottom: 2.4rem;
    }

    .submit {
        width: unset;
        margin-top: 0;
    }

    .rules {
        margin: 2.4rem auto 0;
    }
}

@include xLarge {
    .optIn {
        margin-bottom: 4.8rem;
    }

    .rules {
        margin: 4.8rem auto 0;
    }
}
