@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/fonts';

.contact {
    max-width: var(--block-max-width-plus-padding);
    border-top: 0.1rem rgba(0, 30, 96, 20%) solid;
    margin: 0 auto;
    background-color: var(--colors-white);
}

.contactInner {
    padding: 2.4rem 0 1.8rem;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    grid-column: 1 / -1;
}

.headline {
    padding-bottom: 2rem;
}

.contactForm {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
}

.addressBlock,
.nameBlock {
    display: flex;
    flex-direction: column;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;

    label {
        text-transform: uppercase;
    }

    label + p {
        max-width: 75%;
        padding-top: 1rem;
        margin: 0;
    }
}

.inlineRadioGroup {
    padding-bottom: 2.4rem;
}

.productSelects {
    display: block;
    padding: 0 0 4rem;
    border: none;
    margin-top: -3rem;

    .inputWrapper {
        padding-bottom: 1rem;
    }
}

.input {
    border-radius: 0; // remove input element rounding on ios
    appearance: none;
    color: var(--colors-blue);

    &:-webkit-autofill {
        -webkit-text-fill-color: var(--colors-blue);
    }
}

.input,
.select {
    width: 100%;
    padding: 1.2rem 0;
    border: 0;
    border-bottom: 1px solid rgba(color('blue'), 0.25);

    &::placeholder {
        color: rgba(color('blue'), 0.4);
        font-weight: 500;
    }
}

.select {
    color: var(--colors-blue);

    &.isDefault {
        color: rgba(color('blue'), 0.4);
    }
}

.message {
    width: 100%;
    border-color: rgba(color('blue'), 0.25);
    border-radius: 0;
    margin-top: 1.2rem;
    color: var(--colors-blue);

    &::placeholder {
        color: rgba(color('blue'), 0.4);
        font-weight: 500;
    }
}

.hasError {
    border-color: var(--colors-cheeseSharpCheddar);
}

.error {
    color: var(--colors-cheeseSharpCheddar);
    letter-spacing: 0;
    text-transform: none;
}

.checkbox {
    position: relative;
    display: flex;
    width: 100%;
    padding-bottom: 1.2rem;
    color: var(--colors-blue);
    cursor: pointer;
    text-align: left;
    user-select: none;

    input {
        position: relative;
        cursor: pointer;
        opacity: 0;
    }

    input:checked ~ .checkmark {
        background-color: var(--colors-blue);

        &::after {
            display: block;
        }
    }
}

.checkLabel {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.checkmark {
    position: relative;
    width: 2.2rem;
    height: 2.2rem;
    flex-shrink: 0;
    border: 2px solid var(--colors-blue);
    border-radius: 2px;
    background-color: var(--colors-white);

    &::after {
        position: absolute;
        display: none;
        content: '';
    }
}

.checkbox .checkmark::after {
    top: 2px;
    left: 6px;
    width: 7px;
    height: 12px;
    border: solid var(--colors-cream);
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    transform: rotate(45deg);
}

.errorMessage {
    color: var(--colors-cheeseSharpCheddar);
}

.successMessage {
    color: var(--colors-cheeseMexican4Cheese);
}

.errorMessage,
.successMessage {
    display: inline-block;
    padding: 1rem 0;
}

.submitRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.2rem;
}

.button {
    width: 100%;
    margin-bottom: 2.4rem;
}

@include medium {
    .contact {
        background-color: inherit;
    }

    .button {
        max-width: fit-content;
    }

    .contactInner {
        padding: var(--block-padding-end) 2.4rem;
    }

    .addressBlock {
        flex-direction: row;
        justify-content: space-between;
        gap: 4rem;

        .inputWrapper:first-child {
            flex-basis: 52%;
        }

        .inputWrapper:nth-child(2) {
            flex-basis: 24%;
        }

        .inputWrapper:nth-child(3) {
            flex-basis: 24%;
        }
    }

    .nameBlock {
        flex-direction: row;
        justify-content: space-between;
        gap: 4rem;

        .inputWrapper {
            flex-basis: 50%;
        }
    }

    .submitRow {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2.4rem;
    }

    .checkbox {
        padding-bottom: 1.8rem;
    }
}

@include large {
    .contactInner {
        --column-width: calc((100% - (var(--gridContainer-gap) * 7)) / 8);

        padding: var(--block-padding-end) var(--column-width);
        grid-column: 1 / -1;
    }

    .checkbox {
        padding-bottom: 2.4rem;
    }

    .inlineRadioGroup {
        flex-direction: row;
        align-items: center;
        padding-bottom: 4rem;

        label:first-child {
            display: flex;
            min-width: 50rem;
            height: 2.5rem;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }
}
