@import 'styles/animations';

@mixin circle-small {
    width: 2.7rem;
    height: 2.7rem;
    border-radius: 50%;
}

@mixin circle-large-thick {
    width: 6.8rem;
    height: 6.8rem;
    border: 0.3rem solid;
    border-radius: 50%;
}

.root {
    position: relative;
    display: flex;
    overflow: visible;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    margin: 0;
    appearance: none;
    background: transparent;
    color: inherit;
    font: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    line-height: normal;
    outline: none;
    text-align: left;
    text-transform: uppercase;
    transition: all $fast;
}

.icon {
    position: relative;

    &-chevronRight,
    &-chevronLeft {
        width: 0.8rem;
        height: 1.2rem;
    }

    &-chevronRight {
        left: 0.1rem;
    }

    &-chevronLeft {
        left: -0.1rem;
    }

    &-chevronDown {
        top: 0.1rem;
        width: 1.2rem;
        height: 0.8rem;
    }

    &-playVideo {
        width: 1.275rem;
        height: 1.472rem;
        margin-left: 0.2rem;
    }

    &-arrowRight,
    &-arrowLeft {
        width: 4.6rem;
        height: 1rem;
    }

    &-arrowRightRounded {
        width: 1.5rem;
        height: 1.5rem;
    }

    &-plus,
    &-minus {
        width: 1.8rem;
        height: 1.8rem;

        svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
}

.small {
    &-fill-blue {
        @include circle-small;

        background-color: var(--colors-blue);
        color: var(--colors-white);
    }

    &-fill-cream {
        @include circle-small;

        background-color: var(--colors-cream);
        color: var(--colors-blue);
    }

    &-fill-gold {
        @include circle-small;

        background-color: var(--colors-cream);
        color: var(--colors-makersReserveGoldAlt);
    }

    &-fill-makers-black {
        @include circle-small;

        background-color: var(--colors-makersReserveBlack);
        color: var(--colors-white);
    }

    &-fill-white {
        @include circle-small;

        background-color: var(--colors-white);
        color: var(--colors-blue);
    }

    &-fill-orange {
        @include circle-small;

        background-color: var(--colors-orange);
        color: var(--colors-blue);
    }

    &-fill-bluedark {
        @include circle-small;

        background-color: var(--colors-blueDark);
        color: var(--colors-cream);
    }

    &-fill-creamdark {
        @include circle-small;

        background-color: var(--colors-cream);
        color: var(--colors-blue);
    }
}

.large {
    &-thick-blue {
        @include circle-large-thick;

        border-color: var(--colors-blue);
        color: var(--colors-blue);
    }

    &-thick-cream {
        @include circle-large-thick;

        border-color: var(--colors-cream);
        color: var(--colors-cream);
    }
}
