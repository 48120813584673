@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;

    display: none;
    align-items: center;
    padding: 0;
    border: 0.1rem solid rgba(color('blue'), 0.25);
    margin: 2.3rem;
    border-radius: 4rem;
    grid-column: 1/-1;

    label {
        display: flex;
        width: 100%;
    }

    input {
        width: 100%;
        padding: 0rem 2rem;
        border: none;
        background: transparent;
        color: var(--colors-blue);
        font-weight: 500;
        line-height: 200%;

        &:focus {
            outline: none;

            &::placeholder {
                color: rgba(color('blue'), 0.25);
            }
        }

        &::placeholder {
            color: rgba(color('blue'), 0.5);
        }
    }
}

.searchIcon {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    margin: 1rem;
    cursor: pointer;
}

@include medium {
    .root {
        display: flex;
        min-width: 36rem;
        grid-column: unset;
        grid-column-end: -1;
    }
}
