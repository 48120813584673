@import 'styles/colors';
@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    --grid-template: repeat(1, 1fr);

    position: relative;
    padding: var(--block-padding-end) 2.4rem;
    background-color: var(--background-color);
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.inner {
    position: relative;
    display: grid;
    max-width: var(--block-max-width);
    align-items: center;
    margin: 0 auto;
    grid-auto-columns: 1fr;
    grid-gap: 1.2rem;
    grid-template-columns: var(--grid-template);
    row-gap: 4rem;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: 1 / -1;
    text-align: center;
}

.icon {
    position: relative;
    width: var(--icon-width, 6rem);
    height: var(--icon-height, 6rem);
    margin-bottom: var(--icon-margin-bottom, 2.4rem);
}

.media {
    position: relative;
    grid-column: 1 / -1;

    video {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 2rem;
        object-fit: cover;
    }
}

.videoPlayerAspect {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 72%;
}

.videoPlayer {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
}

.parallaxImage {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 2rem;

    .imagePortrait & {
        aspect-ratio: 576 / 704;
    }

    .imageLandscape & {
        aspect-ratio: 8.4 / 6;
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.eyebrow {
    margin: 0 0 1.2rem;
    text-transform: uppercase;

    p {
        margin: 0;
    }
}

.headline {
    text-transform: uppercase;
}

.body {
    padding: var(--body-padding, 2rem 1.2rem 2rem);

    p {
        margin: 0 0 2.4rem;

        &:last-child {
            margin: 0;
        }
    }
}

.cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;
    text-align: center;
}

.buttonWrapper {
    display: block;
}

.ctaLink {
    span {
        text-align: center;
    }
}

@include medium {
    .eyebrow {
        margin: 0 0 2.4rem;
    }
}

@include medium {
    .root {
        padding: var(--block-padding);

        --grid-template: repeat(13, 1fr);
        --icon-width: 8rem;
        --icon-height: 8rem;
        --body-padding: 3rem 2.4rem 3.6rem;

        &.textAlignLeft {
            --body-padding: 3rem 4.8rem 3.6rem 0;
        }
    }

    .media {
        grid-column: 1 / 8;

        .imagePortrait & {
            grid-column: 1 / 7;
        }
    }

    .imageRight {
        direction: rtl;

        .card {
            order: unset;
            direction: ltr;
        }
    }

    .card {
        grid-column: 9 / -1;

        .imagePortrait & {
            grid-column: 8 / -1;
        }
    }

    .cta {
        margin-bottom: 0;
    }

    .textAlignLeft {
        .card {
            align-items: flex-start;
            text-align: left;
        }
    }
}

@include xLarge {
    .media {
        grid-column: 1 / 8;

        .imagePortrait & {
            grid-column: 1 / 7;
        }
    }

    .card {
        grid-column: 9 / -1;

        .imagePortrait & {
            grid-column: 8 / -1;
        }
    }
}

@include xxxLarge {
    .media {
        grid-column: 1 / 9;

        .imagePortrait & {
            grid-column: 1 / 7;
        }
    }

    .card {
        grid-column: 10 / -1;

        .imagePortrait & {
            grid-column: 8 / -1;
        }
    }
}
