@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    border-top: 0.1rem rgba(color('blue'), 0.2) solid;
}

.grid {
    position: relative;
    display: grid;
    overflow: hidden;
    grid-auto-columns: 1fr;
    grid-template-areas: var(--gridColumnAreas);

    --gridColumnAreas: 'a a';
}

.products {
    border-top: 0.1rem rgba(color('blue'), 0.2) solid;
    background-color: rgba(251, 250, 241, 100%);
}

.searchButton {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    margin: 2rem;
    cursor: pointer;
    grid-column-end: -1;
    justify-self: right;
}

.showSearch {
    .searchButton,
    .breadcrumbs {
        display: none;
    }

    .search {
        display: flex;
    }
}

.noResults,
.isSearchStalled {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 5rem 2rem;
    font-size: 2.4rem;
    grid-column: 1 / span 3;
}

@include medium {
    .searchButton {
        display: none;
    }

    .search {
        display: flex;
    }

    .showSearch {
        .breadcrumbs {
            display: flex;
        }
    }
}

@include large {
    .grid {
        --gridColumnAreas: 'a a a';
    }
}
