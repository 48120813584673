@import 'styles/breakpoints';

.root {
    padding: 3rem;
    border: 1px solid rgba(0, 30, 96, 15%);
    border-radius: 2rem;
    color: var(--colors-blue);

    ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        gap: 1rem;
    }

    li {
        display: flex;
        align-items: center;
        list-style: none;
    }
}

.headline {
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.icon {
    margin-right: 1.6rem;
}

@include large {
    .headline {
        text-align: left;
    }
}
