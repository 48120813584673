@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    padding: 0;
    border: 0;
    border-right: 0.1rem solid rgba(color('blue'), 0.2);
    border-bottom: 0.1rem solid rgba(color('blue'), 0.2);
    margin: 0;
    appearance: none;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    outline: none;
    text-transform: none;

    &:nth-child(2n) {
        border-right: none;
    }

    &:nth-child(2n + 1):nth-last-child(-n + 2),
    &:nth-child(2n + 1):nth-last-child(-n + 2) ~ button {
        border-bottom: none; // target last row
    }

    &::after {
        display: block;
        padding-top: 100%;
        content: '';
    }

    &:focus-visible {
        border: 0.2rem solid blue !important;
    }
}

.image {
    width: 20vw;
    height: 20vw;
    margin: 0 auto 1rem;
    object-fit: contain;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    background-color: var(--colors-white);
    gap: 1rem;
    text-align: center;

    @media (hover: hover) {
        &:hover {
            background-color: rgba(250, 251, 252, 100%);
            cursor: pointer;
        }
    }

    > div {
        &.isClicked {
            opacity: 0;
            transform: scale(1);
            transition: transform var(--timing) var(--scaleEasing),
                opacity var(--timing) var(--opacityEasing);
        }
    }
}

.label {
    max-width: 36rem;
    padding-top: 1rem;
    font-size: clamp(1.6rem, 0.2641rem + 3.5623vw, 3rem);

    // between viewport width 375px to 768px, scale from size 1.6rem to 3rem
    // https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
}

@include medium {
    .content {
        padding: 0 2.4rem;
    }

    .image {
        margin-bottom: 2.4rem;
    }
}

@include large {
    .root {
        &:nth-child(2n) {
            border-right: 0.1rem solid rgba(color('blue'), 0.2);
        } // reset border values

        &:nth-child(2n + 1):nth-last-child(-n + 2),
        &:nth-child(2n + 1):nth-last-child(-n + 2) ~ button {
            border-bottom: 0.1rem solid rgba(color('blue'), 0.2);
        } // reset border values

        &:nth-child(3n) {
            border-right: none;
        }

        &:nth-child(3n + 1):nth-last-child(-n + 3),
        &:nth-child(3n + 1):nth-last-child(-n + 3) ~ button {
            border-bottom: none; // target last row
        }

        &:focus-visible {
            border: 0.2rem solid blue !important;
        }
    }

    .label {
        max-width: 42rem;
        font-size: clamp(2.4rem, -0.2034rem + 2.5424vw, 3rem);

        // between viewport width 1024px to 1260px, scale from size 2.4rem to 3rem
    }

    .image {
        width: min(15vw, 25rem);
        height: min(15vw, 25rem);
    }
}
