@import 'styles/breakpoints';

.root {
    --inner-offset-left: calc((100vw - var(--block-max-width)) / 2);

    position: relative;
    width: 100%;
    background-color: var(--colors-white);
}

.footnote {
    padding: 4.8rem 2.4rem;
    color: var(--colors-blue);

    sup {
        display: inline-block;
        width: 0.8rem;
        margin-left: -0.8rem;
    }

    p {
        padding-left: 0.8rem;
    }
}

@include large {
    .inner {
        display: flex;
        width: 100%;
        max-width: var(--block-max-width-plus-padding);
        justify-content: space-between;
        padding: var(--block-padding-side) 0 0 var(--block-padding-side);
        margin: 0 auto;
    }

    .subnav {
        width: 24rem;
        margin-bottom: 1.9rem;
    }

    .sections {
        width: calc(100% - 24rem);
    }

    .footnote {
        width: calc(100% - 33.6rem);
        padding-left: 0;
        margin-left: 33.6rem;

        sup {
            width: 1.2rem;
            margin-left: -1.2rem;
        }

        p {
            padding-left: 1.2rem;
        }
    }
}

@include xLarge {
    .inner {
    }

    .subnav {
        width: 32.4rem;
    }

    .sections {
        width: calc(100% - 32.4rem);
    }

    .footnote {
        width: calc(100% - 51.6rem);
        margin-left: 51.6rem;
    }
}

@include blockMaxWidthPlusPadding {
    .inner {
        width: calc(100% - var(--inner-offset-left));
        max-width: none;
        padding: 9.6rem 0 0;
        margin-left: var(--inner-offset-left);
    }

    .footnote {
        width: calc(100% - var(--inner-offset-left) - 32.4rem - 9.6rem);
        margin-left: calc(var(--inner-offset-left) + 32.4rem + 9.6rem);
    }
}
