@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/fonts';

.root {
    padding: 0 1.8rem;
    border-top: 0.1rem rgba(color('blue'), 0.2) solid;
}

.inner {
    max-width: 80rem;
    padding: 4.8rem 0;
    margin: 0 auto;
}

.headerText {
    display: flex;
    flex-direction: column;
    padding-bottom: 2.4rem;

    h1 {
        max-width: 31.6rem;
        padding-bottom: 2.4rem;
        text-transform: uppercase;
    }

    svg {
        max-width: 34rem;
    }
}

.header {
    display: flex;
    flex-direction: column;
    margin-bottom: 4.8rem;
}

.examplesContainer {
    position: relative;
    padding: 2.4rem;
    border-radius: 2rem;
    margin-bottom: 1.2rem;
    background-color: rgba(0, 30, 96, 5%);
}

.examplesHeadline {
    position: relative;
    padding-right: 4rem;
    padding-bottom: 2.4rem;

    p,
    span {
        display: inline;
    }

    svg {
        width: 2.4rem;
        color: var(--colors-orange);
    }
}

.examplesList {
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    border: 1px solid rgba(0, 30, 96, 10%);
    border-radius: 0.8rem;

    p {
        padding-bottom: 1.7rem;
        margin: 0;
    }
}

.howToFindIt {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-transform: uppercase;
}

.examples {
    text-transform: uppercase;
}

.examplesItem {
    display: flex;
    min-width: 20rem;
    flex-direction: column;
    padding: 1.4rem;
    border-radius: 6.61px;
    margin-bottom: 0.5rem;
    background-color: var(--colors-white);
    font-family: $Register;
    font-size: 0.9rem;
    text-transform: uppercase;

    span {
        padding-bottom: 0.3rem;

        &:last-child {
            padding-bottom: 0;
        }
    }

    em {
        color: var(--colors-orange);
        font-style: normal;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 4.8rem;

    button:first-child {
        margin-bottom: 1.2rem;
    }
}

@include medium {
    .headerText {
        h2 {
            max-width: 46rem;
        }

        svg {
            max-width: 40rem;
        }
    }

    .buttonContainer {
        flex-direction: row;

        button:first-child {
            margin-right: 1.2rem;
            margin-bottom: 0;
        }
    }
}

.magnifier {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1rem;
}

@include large {
    .headerText {
        max-width: 45.2rem;
        min-height: 24rem;
        padding-bottom: 4.8rem;

        h2 {
            max-width: 30rem;
        }
    }

    .header {
        flex-direction: row;
        justify-content: space-between;
    }

    .inner {
        padding: 9.6rem 0;
    }

    .examplesContainer {
        padding: 6rem;
    }

    .examplesList {
        display: flex;
        flex-direction: row;

        p {
            padding-bottom: 2.4rem;
        }
    }

    .help {
        display: flex;
        max-width: 20rem;
        flex-direction: column;
        justify-content: flex-start;
    }

    .helpCopy {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 1.3rem;
        font-family: $GTWalsheim;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5;
    }

    .examples {
        margin-left: 3rem;
    }

    .examplesItem {
        min-width: 40rem;
        margin-bottom: 1rem;
        font-size: 1.4rem;

        span {
            padding-bottom: 0.5rem;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
