@import 'styles/breakpoints';

.root {
    position: relative;
    display: block;
    overflow: hidden;
    width: 83vw;
    max-width: 40.8rem;
    border-radius: 2rem;
    aspect-ratio: 408 / 535;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@include large {
    .root {
        width: 34vw;
    }
}
