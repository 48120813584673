@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2rem;
}

.options {
    display: flex;
    width: 10rem;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.option {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2.4rem;
    margin-bottom: 2.4rem;
    cursor: pointer;
    transition: all $default cubic-bezier(0.215, 0.61, 0.355, 1);

    &:last-child {
        margin-bottom: 0;
    }

    @media (hover: hover) {
        &:hover:not(.selected) {
        }
    }

    &:active,
    &:focus {
    }
}

.selected {
    cursor: default;
}

.radio {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.label {
    width: 100%;
    height: 2rem;
    align-self: center;
    cursor: pointer;
    text-transform: none !important;
    user-select: none;
}

.fauxRadio {
    height: 2.4rem;
    margin-right: 1.2rem;
    color: var(--colors-blue);
}

@include large {
    .root {
        height: 2.5rem;
        margin-top: 0;
        justify-items: center;
    }
}
